import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import { PageContentLayout } from '../../components/PageContentLayout';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};

const MDXLayout = props => <PageContentLayout headProps={{
  title: 'Contributing'
}} {...props} />;

export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Contributing`}</h1>
    <blockquote>
      <p parentName="blockquote"><strong parentName="p">{`The project is not fully open for sustancial contributions yet until the
`}<inlineCode parentName="strong">{`v1.0.0-beta.1`}</inlineCode>{` is released.`}</strong></p>
    </blockquote>
    <p>{`All your contributions are welcome!`}</p>
    <p>{`There are many ways to help out:`}</p>
    <ul>
      <li parentName="ul">{`If you have found a bug, report it on GitHub.`}</li>
      <li parentName="ul">{`Work on open issues.`}</li>
      <li parentName="ul">{`Add unit tests to increase use case coverage.`}</li>
      <li parentName="ul">{`Improve the documentation.`}</li>
      <li parentName="ul">{`Blog about how to use the framework.`}</li>
      <li parentName="ul">{`Create new tools based on Arwes systems.`}</li>
    </ul>
    <p>{`When contributing to this repository, please first discuss the change you wish to
make `}<a parentName="p" {...{
        "href": "https://github.com/arwes/arwes/issues/new"
      }}>{`via GitHub issue`}</a>{` with the owners
of this repository before making a change.`}</p>
    <p>{`Have a question or an idea? Share it on `}<a parentName="p" {...{
        "href": "https://discord.gg/s5sbTkw"
      }}>{`Discord`}</a>{`!`}</p>
    <p>{`Please note we have a `}<a parentName="p" {...{
        "href": "/community/code-of-conduct"
      }}>{`code of conduct`}</a>{`, please follow
it in all your interactions.`}</p>
    <p>{`Read the following guides to contribute to the project:`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/project/contributing/workflow"
        }}>{`Contribution workflow`}</a>{`.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/project/contributing/architecture"
        }}>{`Project architecture`}</a>{`.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/project/contributing/playground"
        }}>{`Contributing to the playground`}</a>{`.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/project/contributing/website"
        }}>{`Contributing to the website`}</a>{`.`}</li>
    </ul>
    <p>{`Thank you for your contributions!`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      